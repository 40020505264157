/************ animate style ************/
.top_image_bounce {
  animation: top-image-bounce 3s infinite ease-in-out;
}
.left_image_bounce {
  animation: left-image-bounce 3s infinite ease-in-out;
}
.right_image_bounce {
  animation: right-image-bounce 3s infinite ease-in-out;
}
.spin_image {
  animation: spin 3s infinite ease-in-out;
}

@-webkit-keyframes top-image-bounce {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes top-image-bounce {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-webkit-keyframes left-image-bounce {
  0% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}
@keyframes left-image-bounce {
  0% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
  }
}

@keyframes ripple-white3 {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0);
  }
}

// @keyframes bannerFadeInLeft {
//   0% {
//     opacity: 0;
//     -webkit-transform: translate3d(0, 50%, 0);
//     transform: translate3d(0, 50%, 0);
//   }
//   to {
//     opacity: 1;
//     -webkit-transform: none;
//     transform: none;
//   }
// }

@-webkit-keyframes bannerFadeInLeft {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes bannerFadeInLeft {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes bannerFadeInRight {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes bannerFadeInRight {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
